import { Box, Center, keyframes, useTheme } from "@chakra-ui/react"

export const LoadingLogo = (): JSX.Element => {
  const theme = useTheme()
  const stokeAnim = keyframes`
    to {
        stroke-dashoffset: 650px;
    } 
    `
  const fadeIn = keyframes`
    to {
      opacity: 0;
      transform: scale(0);
    }
  `

  return (
    <Center flexGrow={1}>
      <Box
        borderWidth={1}
        rounded="full"
        p={1}
        animation={`${fadeIn} backwards reverse ease-in .14s`}
      >
        <Box
          animation={`${stokeAnim} linear infinite 1.5s`}
          shadow="sm"
          rounded="full"
          borderWidth={1}
          borderColor="gray.100"
          p={5}
        >
          <svg viewBox="0 0 159.8 181.74" width="50px" height="50px">
            <path
              fill="none"
              stroke={theme.colors.gray[200]}
              strokeMiterlimit={10}
              strokeWidth="14.2px"
              d="M6.9,136.2l61.9,35.7c1.6.9,5.8,2.7,11.1,2.7s8.9-1.5,11.1-2.7l52.6-30.4c8.2-5.3,9.1-11.7,9.1-15.7v-14.1h-12.7c-22.7,0-29.3-20.5-34.7-37.8-6.9-22.2-13.4-30.4-25.4-30.4s-18.2,9.6-24.3,26.7-10.5,41.5-35.8,41.5H7.1v-53.9c.5-5,1-7.5,2.6-10.1s3.5-5.4,7.8-7.9l26-15,26-15c3.2-1.6,7.4-2.7,10.4-2.7s7.6,1,10.4,2.7l26,15,26,15c2.8,1.8,6.1,5,7.6,7.6s2.8,6.3,2.8,10.4v33.2"
            />
            <path
              fill="none"
              stroke={theme.colors.brand[500]}
              strokeMiterlimit={10}
              strokeWidth="14.2px"
              strokeDasharray="300px 350px"
              d="M6.9,136.2l61.9,35.7c1.6.9,5.8,2.7,11.1,2.7s8.9-1.5,11.1-2.7l52.6-30.4c8.2-5.3,9.1-11.7,9.1-15.7v-14.1h-12.7c-22.7,0-29.3-20.5-34.7-37.8-6.9-22.2-13.4-30.4-25.4-30.4s-18.2,9.6-24.3,26.7-10.5,41.5-35.8,41.5H7.1v-53.9c.5-5,1-7.5,2.6-10.1s3.5-5.4,7.8-7.9l26-15,26-15c3.2-1.6,7.4-2.7,10.4-2.7s7.6,1,10.4,2.7l26,15,26,15c2.8,1.8,6.1,5,7.6,7.6s2.8,6.3,2.8,10.4v33.2"
            />
          </svg>
        </Box>
      </Box>
    </Center>
  )
}

export const FeedLoadingLogo = ({ center }: { center?: boolean }) => {
  // 180px and 100px offsets were chosen to work on a standard desktop screen
  // the ultimate aim to try and centralise to the viewport and not the
  // container

  const adjustPosition = center ? { mt: 180 } : { mt: 180, mr: 100 }

  return (
    <Box {...adjustPosition}>
      <LoadingLogo />
    </Box>
  )
}
